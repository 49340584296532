$(document).ready(function(){

    setupCommonFunctions();
    addAdditionalMethods();
    setupAjax();

    $(".password_show").click(function () {
        var input = $(this).closest('.form-group').find('input');
        if (input.attr("type") == "password") {
            $(this).closest('.form-group').find('i').removeClass('icon-lock').addClass('icon-unlock-password');
            input.attr("type", "text");
        } else {
            $(this).closest('.form-group').find('i').removeClass('icon-unlock-password').addClass('icon-lock');
            input.attr("type", "password");
        }
    });

    $('body').on('keyup change blur focus keydown', 'input, select, textarea', function(e){
        if ($(this).closest('.form-group').hasClass('invalid') && $(this).closest('.form-group').find('.js-error').length) {
            $(this).closest('.form-group').removeClass('invalid');
            $(this).closest('.form-group').find('.js-error').remove();
        }
    });

    $('body').on('click', '.close-toaster', function(){
        $(this).closest('.bv-toaster').remove();
    });
    $("select").on("select2:close", function (e) {
        if($(this).closest('form').data('validator')){
            $(this).valid();
        }
    });

    $(".cmn-cust-dropdown .toogle-drodpdown").click(function(){
        $("body").removeClass('js-menu-expnad');
        $(".cmn-cust-dropdown .cmn-drodp-opt").slideToggle();
        $('.cmn-cust-dropdown').toggleClass('js-drop-expand');
    });

    $("header .mobile-menu-bar").click(function(){
        $(".cmn-cust-dropdown .cmn-drodp-opt").slideUp();
        $('.cmn-cust-dropdown').removeClass('js-drop-expand');
        $("body").toggleClass('js-menu-expnad');
        
    });
});
function setupCommonFunctions()
{
    window.showLoader = function() {
        $(".cmn-bv-loader").show();
    }

    window.hideLoader = function() {
        $(".cmn-bv-loader").hide();
    }
    
    window.getErrorFromException = function(jXhr)
    {
        var errors = jXhr.responseJSON.meta.message;
        if (jXhr.responseJSON.errors) {
            var errors = "";
            $.each(jXhr.responseJSON.errors, function(i, v){
                errors = errors + v+"<br/>";
            });
        }
        return errors;
    }
    window.showErrorAlertFromException = function(jXhr, form = '')
    {
        if (jXhr.status == 419) {
            window.location.reload();   
        }
        if (jXhr.status == 401) {
            window.location.reload();
        }

        if (jXhr.responseJSON.meta.message_code == 'VALIDATION_ERROR' && jXhr.responseJSON.errors) {
            showFormError(jXhr, form);
        } else {
            var message = getErrorFromException(jXhr);
            errorToaster(message);
        }
        
    }
    window.showFormError = function (jXhr, form) {
        $(form).find('.js-error').remove();
        if (jXhr.responseJSON.errors) {
            $.each(jXhr.responseJSON.errors, function (index, val) {
                $(form).find(':input[name="' + getFieldnameFromLaravelDotName(index) + '"]').closest('.form-group').addClass('invalid');
                $(form).find(':input[name="' + getFieldnameFromLaravelDotName(index) + '"]').closest('.form-group').append('<span class="js-error">'+ val.join(', ') +'</span>');
            });
        }
    }
    window.getFieldnameFromLaravelDotName = function(laravelDotName) {
        let parts = laravelDotName.split(".");
        let fieldName = "";
        let prefix = "";
        let suffix = "";
        parts.forEach((part, i) => {
            if (fieldName != "") {
                prefix = "[";
                suffix = "]";
            }
            fieldName += prefix + part + suffix;
        });

        return fieldName;
    }
    window.invalidHandlerSubmit = function() {
        $('input, select, textarea').each(function(i, ele){
            if ($(ele).closest('.form-group').hasClass('invalid')) {
                $(ele).closest('.form-group').removeClass('invalid');
                $(ele).closest('.form-group').find('.js-error').remove();
            }
        });
    }
    window.errorMsgPlacement = function(error, element) {
        if ($(element).hasClass("select2-hidden-accessible")) {
            error.insertAfter(element.closest('.input-wrapper'));
        } else if ($(element).hasClass('file_inp')) {
            error.insertAfter(element.closest('.img_upload'));
        } else {
            //error.insertAfter(element.parent());
            error.appendTo(element.parent().parent());
        }
    }
    window.successToaster = function(message) {
        $('body').append('<div class="bv-toaster sucess"><a href="javascript:;" class="close-toaster"><i class="icon-cross-close"></i></a><span class="toaster-icon"><i class="icon-mark"></i></span><div class="toaster-message"><h4>Success!</h4><p class="toast_msg">'+message+'</p></div></div>');
        setTimeout(function(){
            $('.bv-toaster.sucess').addClass('active');
        }, 100);

        setTimeout(function(){
            $('.bv-toaster.sucess').remove();
        }, 10000);
    }
    window.errorToaster = function(message) {
        $('body').append('<div class="bv-toaster eror"><a href="javascript:;" class="close-toaster"><i class="icon-cross-close"></i></a><span class="toaster-icon"><i class="icon-error"></i></span><div class="toaster-message"><h4>Error!</h4><p class="toast_msg">'+message+'</p></div></div>');
        
        setTimeout(function(){
            $('.bv-toaster.eror').addClass('active');
        }, 100);

        setTimeout(function(){
            $('.bv-toaster.eror').remove();
        }, 10000);
    }
    window.warningToaster = function(message) {
        $('body').append('<div class="bv-toaster warning"><a href="javascript:;" class="close-toaster"><i class="icon-cross-close"></i></a><span class="toaster-icon"><i class="icon-warning"></i></span><div class="toaster-message"><h4>Warning!</h4><p class="toast_msg">'+message+'</p></div></div>');
        setTimeout(function(){
            $('.bv-toaster.warning').addClass('active');
        }, 100);

        setTimeout(function(){
            $('.bv-toaster.warning').remove();
        }, 10000);
    }
}
function setupAjax()
{
    var headers = {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        'Platform': 'Web'
    };
    if (appConfig.auth_token) {
        headers.Authorization = 'Bearer '+appConfig.auth_token;
    };
    $.ajaxSetup({
        headers: headers
    });
}
function addAdditionalMethods()
{
    if ($.validator) {
        $.validator.addMethod("validate_email", function (value) {

            if (/^[a-zA-Z0-9]([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-zA-Z0-9]{2,6}(?:\.[a-z]{2})?)$/.test(value)) {
                return true;
            } else {
                return false;
            }
        }, "Please enter a valid Email");

        $.validator.addMethod("validate_phone", function (value) {
            if (value == '') {
                return true;
            }
            if (/^[0-9]{9,10}$/im.test(value)) {
                return true;
            } else {
                return false;
            }
        }, "Please enter a valid Phone Number");

        
        $.validator.addMethod("pwcheck", function(value,element) {
            var result = /^^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!#$%&()*+,-./:;<=>?@[\]^_`{|}~]{6,}$$/.test(value);
            return result;
        }, 'Password should be minimum of 6 characters and must contain at least one number, one special character, and both uppercase and lowercase letters');

        $.validator.addMethod("dateCompare", function(value, element, opt) {
            var start_date = $('#start_date').val();
            var end_date = $('#end_date').val();
            if (start_date != '' && end_date != '') {
                if (Date.parse(start_date) > Date.parse(end_date)) {
                    return false;
                } else {
                    return true;
                }
            }
            return true;
        });

        $.validator.addMethod('latCoord', function (value, element) {
            return this.optional(element) ||
                value.length >= 4 && /^(?=.)-?((8[0-5]?)|([0-7]?[0-9]))?(?:\.[0-9]{1,20})?$/.test(value);
        }, 'Please enter valid Latitude.');

        $.validator.addMethod('longCoord', function (value, element) {
            return this.optional(element) ||
                value.length >= 4 && /^(?=.)-?((0?[8-9][0-9])|180|([0-1]?[0-7]?[0-9]))?(?:\.[0-9]{1,20})?$/.test(value);
        }, 'Please enter valid Longitude.');

        $.validator.addMethod('dimension', function(value, element, param) {
            if(element.files.length == 0){
                return true;
            }
            var width = $(element).attr('data-imagewidth');
            var height = $(element).attr('data-imageheight');
            if(width == param[0] && height == param[1]){
                return true;
            }else{
                return false;
            }
        },'Please upload an image with 100 x 100 pixels dimension');
    }
}